/**
 * This is the Loader component, it is the very first thing
 * loaded, and should therefor be as minimalistic as possible
 * should also not contain any text as the i18n's will not be
 * loaded at this point.
 */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { themeInLightMode } from '../../helpers/theme';
import { preloaderLogoSecondaryLight, preloaderLogoSecondaryDark, shortLogo } from '../../helpers/images';
import { CircularProgress } from '@material-ui/core';

@withStyles(theme => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper
    },
    container: {
        position: 'relative',
        width: 128,
        height: 128,
    },
    logo: {
        position: 'absolute',
        top: 29,
        left: 18,
    },
    textLogo: {
        maxHeight: '40px',
        maxWidth: '300px',
        marginTop: theme.spacing(2),
    },
    circle: {
        position: 'absolute',
    }
}))
class Loader extends Component {

    render() {
        const { classes } = this.props;
        const textLogo = themeInLightMode() ? preloaderLogoSecondaryLight : preloaderLogoSecondaryDark;
        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <CircularProgress className={classes.circle} style={{
                        width: '128px',
                        height: '128px',
                    }} />
                    <img src={shortLogo} alt="logo" className={classes.logo} />
                </div>
                <img src={textLogo} alt="logo" className={classes.textLogo} />
            </div>
        );
    }

}
export default Loader;
