import { createTheme } from '@material-ui/core/styles';


const firmamentBlue = '#0B2B3F';
const tandayapaCloudForest = '#45756C';
const fadedJade = '#3E7A76';
const pineGlade = '#CCCC99';
const sunshade = '#FFA631';
const elephant = '#163E51';

const shuRed = '#ED6000';
// Background
const lightBackgroundPaper = '#DDDCC0';
const lightBackgroundDefault = '#DDDCC0';

const profileGradientLHS = 'linear-gradient(135deg, rgba(22,22,22,1) 0%, rgba(44,44,44,1) 100%)';
const headerGradientDark = 'linear-gradient(97deg, rgb(21, 62, 80) 0%, rgb(38, 85, 95) 100%)';
const headerGradientLight = 'linear-gradient(357deg, #CCCC99 0%, #B5F4F1 100%)';


/**
 * ========== Typography ==========
 * Title: Baskerville
 * Body: AvenirNext
 * Tag: GeorgiaBold
 */
const Theme = (darkMode) => createTheme({
    typography: {
        fontFamily: 'Teko, sans-serif',
        h1: { fontFamily: 'TekoBold, sans-serif' },
        h2: { fontFamily: 'TekoBold, sans-serif' },
        h3: { fontFamily: 'TekoBold, sans-serif' },
        h4: { fontFamily: 'TekoBold, sans-serif' },
        h5: { fontFamily: 'TekoBold, sans-serif' },
        h6: { fontFamily: 'TekoRegular, sans-serif', fontSize: '21px', textTransform: 'uppercase' },
        caption: {
            fontFamily: 'TekoRegular, sans-serif',
            fontSize: '40px',
            textTransform: 'uppercase',
            lineHeight: 0.6,
            color: darkMode ? sunshade : '#ffffff',
        },
        body1: {
            fontSize: '14px',
            fontFamily: 'ArchivoRegular, sans-serif',
        }
    },
    palette: {
        type: darkMode ? 'dark' : 'light',
        text: {
            primary: darkMode ? '#ffffff' : firmamentBlue,
            hint: darkMode ? pineGlade : tandayapaCloudForest
        },
        primary: {
            main: fadedJade,
            contrastText: '#fff',
        },
        secondary: {
            main: shuRed,
            contrastText: '#fff',
        },
        background: {
            paper: darkMode ? firmamentBlue : lightBackgroundPaper,
            popoverPaper: darkMode ? fadedJade : '#DDDCC0',
            default: darkMode ? firmamentBlue : lightBackgroundDefault,
            header: darkMode ? headerGradientDark : headerGradientLight,
            payment: darkMode ? '#163e51' : '#95B7A6',
            footer: darkMode ? `transparent linear-gradient(0deg, #163E51 0%, ${fadedJade} 100%) 0% 0% no-repeat padding-box` : 'transparent linear-gradient(180deg, #B5F4F1 0%, #CCCC99 100%) 0% 0% no-repeat padding-box',
            accordion: darkMode ? elephant : pineGlade,
            profileInfoPopper: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'white',
            activeCategory: darkMode ? 'rgba(255, 255, 255, 0.3)' : firmamentBlue
        },
    },
    custom: {
        text: {
            table: darkMode ? 'white' : 'rgba(44,44,44,1)',
            currency: darkMode ? pineGlade : shuRed,
            shadow: darkMode ? `1px 1px ${elephant}, 2px 2px ${elephant}` : '1px 1px white, 2px 2px white',
        },
        size: {
            headerHeight: '103px',
            footerHeight: '58px',
            // maxPageWidth: '1570px'
            maxPageWidth: '1536px'
        },
        color: {
            gold: '#EBAB50',
            tandayapaCloudForest,
            elephant,
            sunshade,
            shuRed,
            firmamentBlue,
            pineGlade,
            headerGradientDark,
            headerGradientLight
        },
        button: {
            datePicker: {
                backgroundColor: darkMode ? elephant : 'white'
            },
            black: {
                color: '#fff',
                backgroundColor: '#1A1A1A',
                '&:hover': {
                    backgroundColor: '#000000',
                },
            },
            gold: {
                color: '#fff',
                backgroundColor: '#EBAB50',
                '&:hover': {
                    backgroundColor: '#BD8940',
                },
            },
            buyCrypto: {
                textTransform: 'uppercase',
                fontFamily: 'TekoRegular, sans-serif',
                color: 'white',
                backgroundColor: shuRed,
                '&:hover': {
                    backgroundColor: shuRed,
                },
            },
            balance: {
                textTransform: 'uppercase',
                fontFamily: 'TekoRegular, sans-serif',
                color: 'white',
                backgroundColor: fadedJade,
                '&:hover': {
                    backgroundColor: fadedJade,
                },
            },
            deposit: {
                textTransform: 'uppercase',
                fontFamily: 'TekoRegular, sans-serif',
                color: elephant,
                backgroundColor: sunshade,
                '&:hover': {
                    backgroundColor: sunshade,
                },
            },
            withdraw: {
                textTransform: 'uppercase',
                fontFamily: 'TekoRegular, sans-serif',
                color: elephant,
                backgroundColor: pineGlade,
                '&:hover': {
                    backgroundColor: pineGlade,
                },
            },
            colorMode: {
                textTransform: 'uppercase',
                fontFamily: 'TekoRegular, sans-serif',
                color: 'text',
                backgroundColor: darkMode ? elephant : sunshade,
            },
            showMore: {
                fontFamily: 'TekoRegular',
                fontSize: 30,
                lineHeight: 1.2
            }
        },
    },
    profileGradientLHS,
    hexToRGB: (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}` : null;
    }
});

export default Theme;
